import { createGlobalStyle } from "styled-components";
import { colors } from "./colors";
import { breakpoints } from "./breakpoints";

export const theme = {
  colors,
  breakpoints,
};

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${theme.colors.hex.white};
    margin: 0;

    font-family: 'DM Sans', sans-serif;

    & > div {
      overflow-x: hidden;
    }
  }

  html, body {
    scroll-behavior: smooth;
  }

  * > * {
    margin: 0;
  }
`;
