const hex = {
  primary: {
    normal: "#2E6EFF",
    dark: "#00001B",
  },
  secondary: "#F9116C",
  white: "#FFFFFF",
  black: "#000000",
  gray: {
    darker: "#282828",
    dark: "#999999",
    light: "#E3E3E3",
    lighter: "#E9E9E9",
  },
  status: {
    success: "#81C784",
  },
};

const rgb = {
  primary: "46, 110, 255",
  secondary: "249, 17, 108",
  white: "255, 255, 255",
  black: "0, 0, 0",
};

const gradient = {
  primary: "linear-gradient(87.08deg, #568AFF -1.13%, #FF0060 98.95%)",
};

export const colors = {
  hex,
  rgb,
  gradient,
};
